let IsBtns = document.querySelectorAll('.js-infoSectionShowMore');
IsBtns.forEach(curr => {
    curr.addEventListener('click', function(e) {
        if(!e.target.classList.contains('js-infoSectionShowLess')) {
            curr.parentElement.parentElement.classList.add('hide-text');
            setTimeout(() => {
                curr.parentElement.parentElement.classList.add('hide-img');
                curr.parentElement.parentElement.classList.add('resize-text');
                setTimeout(() => {
                    curr.text = 'Zobraziť menej';
                    curr.classList.add('js-infoSectionShowLess');
                    curr.parentElement.parentElement.classList.add('show-text');
                }, 500);
            }, 500);
        }
    });
});

document.addEventListener('click', function(e) {
    if(e.target.classList.contains('js-infoSectionShowLess')) {
        var curr = e.target;
        curr.parentElement.parentElement.classList.remove('show-text');
        setTimeout(() => {
            curr.parentElement.parentElement.classList.remove('hide-img');
            curr.parentElement.parentElement.classList.remove('resize-text');
            setTimeout(() => {
                curr.text = 'Zobraziť Viac';
                curr.classList.remove('js-infoSectionShowLess');
                curr.parentElement.parentElement.classList.remove('hide-text');
            }, 1000);
        }, 1000);
    }
});
